body {
    background-color: var(--denim-background-color);
    color: var(--denim-color);

    /* NOTE: This is here to stop the horizontal scrollbar that appears when the account menu is rendered after clicking
       the Account button. It's something that happens on pages that have a vertical scrollbar, and this was the easiest
       solution. We're talking about an overflow of ~15px, which is the width of the vertical scrollbar. */
    /* stylelint-disable selector-class-pattern */
    &.ReactModal__Body--open {
        overflow-x: hidden;
    }
    /* stylelint-enable selector-class-pattern */
}

figure {
    --figure-margin: 0;

    margin: var(--figure-margin);
}

.health-insights {
    display: flex;
    flex-flow: column nowrap;
    min-height: 100vh;

    & main {
        flex-grow: 10;
    }
}

.account-menu {
    & .link:hover {
        --link-color: var(--genomics-color-light-green);
    }
}

.logotype {
    &.home-link {
        cursor: pointer;
    }

    .navbar-new & {
        height: 32px;

        &.powered-by {
            height: 54px;
        }
    }

    .footer & {
        height: 48px;
    }

    @media screen and (width <= 375px) {
        & {
            .navbar-new & {
                &.powered-by {
                    height: 46px;
                }
            }
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            .footer & {
                height: 54px;
            }
        }
    }
}

.footer {
    &.primary-footer {
        --footer-padding: var(--denim-spacing-32) 0 var(--denim-spacing-32) 0;

        & .footer-content {
            grid-column: -1 / 1;
        }

        & .logotype-container,
        & .links,
        & .list-of-links {
            flex-grow: 1;
        }

        & .logotype-container,
        & .list-of-links {
            align-self: flex-start;
        }

        & .logotype-container {
            margin-bottom: var(--denim-spacing-32);
        }

        & .links {
            & .link {
                --link-text-decoration-line: none;

                margin: 0 0 var(--denim-spacing-24);

                &.active {
                    border-left: var(--ihr-active-link, var(--denim-active-menu-item, 2px solid var(--genomics-color-light-green, rgb(51 255 196))));
                    padding-left: var(--denim-spacing-16);
                }
            }
        }
    }

    &.legal-footer {
        padding: var(--denim-spacing-16) 0;

        & .legalese {
            color: var(--denim-color-shade-white-alpha-60);
            grid-column: -1 / 1;

            & .link {
                margin-left: var(--denim-spacing-8);
            }
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            &.primary-footer {
                & .links {
                    & a {
                        &:first-of-type {
                            margin-top: 0;
                        }

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            &.primary-footer {
                --footer-padding: var(--denim-spacing-48) 0 var(--denim-spacing-48) 0;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            &.primary-footer {
                --footer-padding: var(--denim-spacing-64) 0 var(--denim-spacing-64) 0;
            }
        }
    }
}

.guard {
    align-items: center;
    display: flex;
    height: 95vh;
    justify-content: center;

    .content & {
        height: 70vh;
    }
}

.dropdown-menu {
    display: none;
    min-width: 244px;
    position: fixed;

    &.open {
        display: inline-block;
        z-index: calc(var(--denim-modal-overlay-z-index, 3) + 1);
    }
}

.dropdown-overlay {
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: var(--denim-modal-overlay-z-index, 3);
}

.center-within {
    --center-within-margin: var(--ihr-margin-mobile);

    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: var(--center-within-margin);

    &.no-margin {
        --center-within-margin: 0;
    }

    .grid & {
        grid-column: -1 / 1;
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            --center-within-margin: var(--ihr-margin-tablet-portrait);
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            --center-within-margin: var(--ihr-margin-tablet-landscape);
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            --center-within-margin: var(--ihr-margin-desktop);
        }
    }
}

.text-spacing {
    margin-left: calc(var(--font-size) / 2);
}

.address-display {
    white-space: pre-line;
}

.back-link {
    --back-link-margin-top: 16px;

    margin-left: -6px;
    margin-top: var(--back-link-margin-top);

    & ~ h1 {
        margin-top: 32px;
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        --back-link-margin-top: 0;
    }
}

.main-menu {
    &.inline {
        & a {
            padding-bottom: 16px;
            padding-top: 16px;

            &.active {
                border-bottom: var(--ihr-active-link, var(--denim-active-menu-item, 2px solid var(--genomics-color-light-green, rgb(51 255 196))));
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            &.sign-up {
                & li + li {
                    margin-left: var(--denim-spacing-24);
                }
            }
        }
    }
}

/* ::TODO:: Decide where we're going to put all these… */

.minimal-layout {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    min-height: calc(100vh - var(--denim-spacing-48));

    & main:not(.activate,.test-reorder) {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        margin-top: var(--ihr-margin-mobile);
    }

    @media (--denim-breakpoint-above-mobile) {
        & {
            & main:not(.activate) {
                margin-top: 0;
            }
        }
    }
}

.access-code-issue,
.not-found,
.error-notice {
    padding: var(--denim-spacing-24) 0;

    & .left {
        align-self: center;
    }

    & .right {
        display: none;
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            padding-top: 0;

            & .right {
                display: block;
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            & .right {
                width: 460px;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            & .right {
                width: 520px;
            }
        }
    }
}

.error-card {
    .error-layout & {
        --error-card-margin: 0 var(--denim-spacing-16);

        max-width: var(--denim-grid-max-width, 1072px);

        @media (--denim-breakpoint-tablet-portrait) {
            & {
                --error-card-margin: 0 var(--denim-spacing-32);
            }
        }

        @media (--denim-breakpoint-tablet-landscape) {
            & {
                --error-card-margin: 0 var(--denim-spacing-48);
            }
        }

        @media (--denim-breakpoint-desktop) {
            & {
                --error-card-margin: 0 auto;
            }
        }
    }

    .guard & {
        width: 80vw;
    }

    .config-error & {
        align-self: center;
        height: fit-content;
    }
}
